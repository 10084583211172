.WordCountDistribution .bar-chart-container {
  height: 120px;
  width: 296px;
}
@media (min-width: 576px) {
  .WordCountDistribution .bar-chart-container {
    height: 120px;
    width: 440px;
  }
}

@media (min-width: 768px) {
  .WordCountDistribution .bar-chart-container {
    height: 120px;
    width: 300px;
  }
}
@media (min-width: 992px) {
  .WordCountDistribution .bar-chart-container {
    height: 120px;
    width: 400px;
  }
}

@media (min-width: 1200px) {
  .WordCountDistribution .bar-chart-container {
    height: 120px;
    width: 480px;
  }

  .WordCountDistribution .bar-chart-container-lg {
    height: 200px;
    width: 960px;
  }
}
