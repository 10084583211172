.ManuscriptView .ManuscriptContent {
	font-family: "Times New Roman", Times, Baskerville, Georgia, serif;
}

.ManuscriptView .RichTextEditor.readOnly .public-DraftEditor-content {
	opacity: 1;
}

.ManuscriptView .numbered-list-group .list-group-item .edit-scene {
	visibility: hidden;
}

.ManuscriptView .numbered-list-group .list-group-item.active .edit-scene,
.ManuscriptView .numbered-list-group .list-group-item.active:hover .edit-scene,
.ManuscriptView .numbered-list-group .list-group-item.active:focus .edit-scene {
	visibility: visible;
}
