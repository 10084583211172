.link {
  color: #337ab7;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.text-muted {
  color: #9e9e9e;
}
