@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,400i,600,700,700i);
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-title {
  font-size: 1.5em;
}

.App-intro {
  font-size: large;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Login .list-inline li + li::before {
  content: " | ";
}
.list-inline {
  color: #9f9fa1;
  font-size: 14px;
}

.LoginForm .fb-btn i {
  font-size: 24px;
  padding: 5px;
  margin-top: -3px;
}

.Loader .spinner {
	width: 64px;
	height: 64px;
	float: none;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.Loader .spinner:after {
	content: " ";
	display: block;
	width: 46px;
	height: 46px;
	margin: 1px;
	border-radius: 50%;
	border: 5px solid red;
	border-color: #8798ad transparent #8798ad transparent;
	-webkit-animation: spinner 1.2s linear infinite;
	        animation: spinner 1.2s linear infinite;
	opacity: 0.4;
}

@-webkit-keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

.ErrorView {
	max-width: 600px;
}


.Dashboard .ProjectListItem {
  border-bottom: 1px solid #e4e8f0;
}

.navbar-default .navbar-brand img {
    opacity: 1;
    transition: none;
}
.MonitorTimer .timer {
	font-size: 24px;
	font-weight: 300;
	text-align: center;
	width: 144px;
}

.MonitorTimer .material-icons.md-28,
.MonitorTimer .material-icons.md-24 {
	cursor: pointer;
	font-size: 28px;
}



.PaidUser {
	width: 10px;
	height: 10px;
	display: block;
}

.WhatIsNew .dropdown-menu {
  width: 380px;
}

.WhatIsNew .dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333333;
  white-space: normal;
}

.WhatIsNew .dropdown-menu > li > a > p > .what-is-new-hyperlink {
  color: #5a53fa;
}

.WhatIsNew .dropdown-menu > li > a > p > .what-is-new-hyperlink:hover {
  text-decoration: underline;
}

.WhatIsNew .indicator {
  width: 8px;
  height: 8px;
  display: block;
  background-color: #ffb818;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 100px;
}

.WhatIsNew .description {
  font-size: 14px;
}



.ProjectCreateModal .form-group .help-block {
	color: #737373;
	font-size: 14px;
	font-weight: normal;
}



.ProjectCreateModal .form-group .help-block {
	color: #737373;
	font-size: 14px;
	font-weight: normal;
}

.ProjectImportSummary .table-responsive td {
	min-width: 120px;
}

.ProjectImportSummary .table-responsive td:first-child {
	min-width: 48px;
}

.ProjectImportSummary .table-responsive td:last-child {
	min-width: 64px;
}

.EmptyState {
}

.ProjectSummary .ProjectProgress .progress-container {
	height: 84px;
	width: 84px;
}

.ProjectSummary .ProjectReward i {
	font-size: 20px;
	color: #fcab53;
}

.ProjectSummary .ProjectReward i.goal {
	font-size: 20px;
	color: #ff3366;
}

.ProjectSummary .ProjectReward .btn {
	padding-left: 4px;
	padding-right: 4px;
}

.RewardSharer {
}

.RewardSharer img,
.RewardSharer iframe,
.RewardSharer embed,
.RewardSharer video,
.RewardSharer object {
	max-width: 100%;
}





.SocialSharing ul.soc {
	padding-left: 0;
	padding-bottom: 0;
	margin-bottom: 0;
}
.SocialSharing ul.soc li {
	display: inline;
	color: #5a54fa;
}

.SocialSharing ul.soc li span,
.SocialSharing ul.soc li a {
	font-size: 30px;
	text-decoration: none;
	cursor: pointer;
	color: #5a54fa;
}

.link {
  color: #337ab7;
  cursor: pointer;
}
.link:hover {
  text-decoration: underline;
}

.text-muted {
  color: #9e9e9e;
}

.Tracker .btn-xs {
  min-width: 24px;
}

.Tracker .list-styled {
  display: flex;
  justify-content: center;
}

.Tracker .list-styled i {
  /* margin-left: -30px;
  margin-top: 0px;
  float: left; */
  font-size: 25px;
  color: #fcab53;
}

.Tracker .list-styled {
  list-style: none;
}

.ContributionHeatmap a .material-icons {
  color: inherit;
}

.ContributionHeatmap #chart-downloader {
  margin-top: -85px;
  padding-bottom: 30px;
}

.ContributionHeatmap .heat-map-container {
  height: 200px;
  width: 296px;
  overflow-y: hidden;
}

@media (min-width: 576px) {
  .ContributionHeatmap .heat-map-container {
    height: 200px;
    width: 360px;
  }
}

@media (min-width: 768px) {
  .ContributionHeatmap .heat-map-container {
    height: 200px;
    width: 360px;
  }
}
@media (min-width: 992px) {
  .ContributionHeatmap .heat-map-container {
    height: 200px;
    width: 360px;
  }
}

@media (min-width: 1200px) {
  .ContributionHeatmap .heat-map-container {
    height: 200px;
    width: 360px;
  }
}

.ContentCrafts .img-wrapper {
  min-height: 187px;
  text-align: center;
}

.ContentCrafts .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.ContentCrafts a:visited {
  color: #8798ad;
}

.ContentFeatured .img-wrapper {
  min-height: 187px;
  text-align: center;
}

.ContentFeatured .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.ContentFeatured a:visited {
  color: #8798ad;
}

.PillarOutline .btn.btn-secondary.active,
.PillarOutline .btn.btn-secondary:focus,
.PillarOutline .btn.btn-secondary:active {
  background-color: #b5b2fe;
  border-color: #b5b2fe;
}

.PillarOutline .btn-group .btn.btn-secondary {
  outline: none;
}


.ProjectDashboard .progress {
  width: 84px;
  margin-bottom: 0;
  height: 12px;
}
.ProjectDashboard .ProgressWrapper {
  width: 144px;
  padding: 12px 0;
}

.ProjectDashboard .ProjectProgress .progress-container {
  height: 196px;
  width: 196px;
}

.ProjectDashboard .ProjectProgress .list-styled {
  display: flex;
  justify-content: center;
}

.ProjectDashboard .ProjectProgress .chart-downloader {
  margin-top: -40px;
}

.ProjectDashboard .ProjectProgress .list-styled,
.ProjectDashboard .ProjectRewards .list-styled {
  list-style: none;
  padding-top: 8px;
  padding-bottom: 8px;
}

.ProjectDashboard .ProjectRewards .list-styled li {
  margin-bottom: 20px;
}

.ProjectDashboard .ProjectRewards .list-styled i,
.ProjectDashboard .ProjectProgress .list-styled i {
  margin-left: -30px;
  margin-top: 0px;
  float: left;
  font-size: 24px;
  color: #fcab53;
}

.ProjectDashboard .ProjectRewards .list-styled i.goal {
  color: #ff3366;
}

.ProjectDashboard .ProjectRewards .RewardContent p:hover {
  cursor: pointer;
  color: #5a54fa;
  text-decoration: underline;
}

.ProjectDashboard a .material-icons {
  color: inherit;
}

.ProjectDashboard h4,
.ProjectDashboard .stats-block small {
  font-size: 16px;
  font-weight: 500;
}

.ProjectDashboard .pillar-summary {
  display: block;
  width: 25px;
  height: 20px;
  background: #dfe1f6;
  margin-right: 10px;
  margin-bottom: 8px;
  float: left;
}

.ProjectDashboard .pillar-active {
  background: rgb(160, 124, 255);
}

.ProjectDashboard .summary-card {
  min-height: 355px;
}

.ProjectDashboard .activity-container {
  background: #ddd;
}

.ProjectDashboard .col-1-override {
  width: 11%;
  padding-right: 0;
}

.ProjectGoalUpdate .react-datepicker__input-container input {
	color: #8798ad;
	border: 1px solid #e4e8f0;
	display: block;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	background-color: #fff;
	background-image: none;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.ProjectGoalUpdate .react-datepicker-wrapper {
	display: block;
}

.ProjectGoalUpdate .react-datepicker__day-name,
.ProjectGoalUpdate .react-datepicker__day,
.ProjectGoalUpdate .react-datepicker__time-name {
	color: #000;
	display: inline-block;
	width: 2.4rem;
	line-height: 2.4rem;
	text-align: center;
	margin: 0.25rem;
	font-size: 1.2rem;
}

.ProjectGoalUpdate .react-datepicker__current-month,
.ProjectGoalUpdate .react-datepicker-time__header,
.ProjectGoalUpdate .react-datepicker-year-header {
	margin-top: 0;
	color: #000;
	font-weight: bold;
	font-size: 1.5rem;
}

.ProjectGoalUpdate .react-datepicker__navigation--previous {
	left: 0px;
}
.ProjectGoalUpdate .react-datepicker__navigation--next {
	right: 0px;
}

.ProjectGoalUpdate .react-datepicker__navigation {
	top: 15px;
	height: 12px;
	width: 18px;
}

.Contributions .btn-xs {
	min-width: 24px;
}

.Contributions .list-styled {
	display: flex;
	justify-content: center;
}

.Contributions .list-styled i {
	margin-left: -30px;
	margin-top: 0px;
	float: left;
	font-size: 25px;
	color: #fcab53;
}

.Contributions .list-styled {
	list-style: none;
}

.ContributionHeatmap a .material-icons {
	color: inherit;
}

.ContributionHeatmap .chart-downloader {
	margin-top: -30px;
}

.ContributionHeatmap .heat-map-container {
	height: 240px;
	width: 296px;
	overflow-y: hidden;
}

@media (min-width: 576px) {
	.ContributionHeatmap .heat-map-container {
		height: 240px;
		width: 360px;
	}
}

@media (min-width: 768px) {
	.ContributionHeatmap .heat-map-container {
		height: 240px;
		width: 360px;
	}
}
@media (min-width: 992px) {
	.ContributionHeatmap .heat-map-container {
		height: 240px;
		width: 360px;
	}
}

@media (min-width: 1200px) {
	.ContributionHeatmap .heat-map-container {
		height: 240px;
		width: 360px;
	}
}

.WordCountDistribution .bar-chart-container {
  height: 120px;
  width: 296px;
}
@media (min-width: 576px) {
  .WordCountDistribution .bar-chart-container {
    height: 120px;
    width: 440px;
  }
}

@media (min-width: 768px) {
  .WordCountDistribution .bar-chart-container {
    height: 120px;
    width: 300px;
  }
}
@media (min-width: 992px) {
  .WordCountDistribution .bar-chart-container {
    height: 120px;
    width: 400px;
  }
}

@media (min-width: 1200px) {
  .WordCountDistribution .bar-chart-container {
    height: 120px;
    width: 480px;
  }

  .WordCountDistribution .bar-chart-container-lg {
    height: 200px;
    width: 960px;
  }
}


.NaNoWriMoLogo{
    height: 80px;
    /* float: right; */
}

.bookflowLogo{
    width: 150px;
    /* float: left; */
}
.DnDColContent {
	/*To prevent the user selecting text as they drag apply this style*/
	/*user-select: none;*/
	/*padding: 15px;*/
	padding-bottom: 15px;
	min-height: 240px;
}

.DnDContainer {
	overflow-y: auto;
}

.DnDColContent .card-grid.movable:hover,
.DnDColContent .card-grid.movable:active,
.DnDColContent .card-grid.movable:focus {
	/*background: #f5f5f5;*/
	border-bottom: 1px solid #dddddd;
	cursor: move;
	box-shadow: 0 4px 6px 0px rgba(0, 40, 100, 0.25);
	margin: -0.2rem -0.2rem 1.7rem 0.2rem;

	/* Apply no select */
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */ /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                 supported by Chrome and Opera */
}

.DnDRowContent .drag-handle {
	float: left;
	margin-left: -4px;
}

.DnDRowContent .drag-handle .material-icons {
	color: #d5d4ff;
}

.DnDRowContent .drag-handle:hover,
.DnDRowContent .drag-handle:active,
.DnDRowContent .drag-handle:focus {
	cursor: move;
}

.DnDRowContent .card-table.movable:active,
.DnDRowContent .card-table.movable:focus {
	border-bottom: 1px solid #e4e8f0;
	background: #f5f5f5;

	/* Apply no select */
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */ /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                 supported by Chrome and Opera */
}

.DnDMoving .card {
	background: #f5f5f5;
	border-bottom: 1px solid #e4e8f0;
}

.ContentGuideList .list-styled {
	list-style: none;
	padding-top: 8px;
	padding-bottom: 8px;
}

.ContentGuideList .list-styled li {
	margin-bottom: 15px;
}

.ContentGuideList .list-styled i {
	margin-left: -30px;
	float: left;
	font-size: 22px;
	line-height: 22px;
}

/*******************************
* MODAL AS LEFT/RIGHT SIDEBAR
* Add "left" or "right" in modal parent div, after class="modal".
* Get free snippets on bootpen.com
*******************************/
.modal.left .modal-dialog,
.modal.right .modal-dialog {
	position: fixed;
	margin: auto;
	width: 320px;
	height: 100%;
	transform: translate3d(0%, 0, 0);
}

.modal.left .modal-content,
.modal.right .modal-content {
	height: 100%;
	overflow-y: auto;
	border-radius: 0;
}

.modal.left .modal-header,
.modal.right .modal-header,
.modal.left .modal-body,
.modal.right .modal-body,
.modal.right .modal-footer,
.modal.left .modal-footer {
	padding: 32px 32px;
}

/*Left*/
.modal.left.fade .modal-dialog {
	left: -320px;
	transition: opacity 0.3s linear, left 0.3s ease-out;
}

.modal.left.fade.in .modal-dialog {
	left: 0;
}

/*Right*/
.modal.right.fade .modal-dialog {
	right: -320px;
	transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
	right: 0;
}

/* ----- MODAL STYLE ----- */
.modal.left .modal-content,
.modal.right .modal-content {
	border-radius: 0;
	border: none;
}

@media (min-width: 768px) {
	.modal.left .modal-dialog,
	.modal.right .modal-dialog {
		width: 480px;
	}

	/*Left*/
	.modal.left.fade .modal-dialog {
		left: -480px;
	}

	/*Right*/
	.modal.right.fade .modal-dialog {
		right: -480px;
	}
}

@media (min-width: 992px) {
	.modal.left .modal-dialog,
	.modal.right .modal-dialog {
		width: 640px;
	}

	/*Left*/
	.modal.left.fade .modal-dialog {
		left: -640px;
	}

	/*Right*/
	.modal.right.fade .modal-dialog {
		right: -640px;
	}
}

.ManuscriptView .ManuscriptContent {
	font-family: "Times New Roman", Times, Baskerville, Georgia, serif;
}

.ManuscriptView .RichTextEditor.readOnly .public-DraftEditor-content {
	opacity: 1;
}

.ManuscriptView .numbered-list-group .list-group-item .edit-scene {
	visibility: hidden;
}

.ManuscriptView .numbered-list-group .list-group-item.active .edit-scene,
.ManuscriptView .numbered-list-group .list-group-item.active:hover .edit-scene,
.ManuscriptView .numbered-list-group .list-group-item.active:focus .edit-scene {
	visibility: visible;
}

.FooterStickyRight .focusMode {
  color: #642de3;
}

.FooterStickyRight .focus-label {
  cursor: pointer;
}

.search-and-replace-highlight {
  border-radius: 3px;
  background: #fcab53a6;
  padding: 3px;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.search-and-replace-index {
  border-radius: 3px;
  background: #ee53fca6;
  padding: 3px;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.match-count {
  text-align: right;
  position: absolute;
  right: 16px;
}

.react-draggable {
  max-width: 370px;
}
.react-draggable .modal-dialog {
  max-width: 370px;
}

.react-draggable .modal-content {
  border-radius: 1;
  border: 1px solid #e6e6e6;
  background-clip: unset;
  box-shadow: 1px 1px 2px 1px rgb(0 0 0 / 15%);
}

.react-draggable .modal-content .modal-header {
  padding: 25px 15px;
  background-color: #e4e8f0;
  cursor: move;
  border-bottom: 1px solid #e4e8f0;
}

.react-draggable .modal-content .modal-header .modal-title {
  font-weight: 600;
  font-size: 16px;
  color: #3a3a3a;
}


.NoteCreate {
  /*box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 40, 100, 0.12);
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	margin-bottom: 1.5rem;*/
}

.NoteCreate .RichEditor-editor .public-DraftEditor-content {
  min-height: 48px;
}

.PillarRight .drag-handle {
  cursor: -webkit-grab;
  cursor: grab;
}

.card.NoteRead {
	background-color: #f5f6fb;
}

.card.NoteEdit {
}

.Note .RichTextEditor {
	margin-right: 8px;
}

.Note .close {
	margin-right: -4px;
	margin-top: 0px;
	color: #8798ad;
}

.Note .RichEditor-editor .public-DraftEditor-content {
	min-height: 40px;
}

.Note ul {
	margin-bottom: 0;
}

.Note {
	position: relative;
	margin-bottom: 1.5rem;
}

.Note .modal,
.Note .modal-backdrop {
	position: absolute;
}
.Note .modal-dialog {
	width: 80%;
	margin: 10px auto;
}


.PillarView select.form-control {
	background: #436b8a;
	color: #fff;
	border-color: #2f4f6f;
	height: 24px;
	padding: 0;
}


.RewardDector {
  /* position: absolute;
  top: 55px;
  left: 15px;
  z-index: 2; */
}

.RewardDector .label.label-warning {
  background-color: "#f2dede";
  color: #fb8e17;
  cursor: pointer;
  font-size: 14px;
}
.RewardDector .label.label-warning .material-icons {
  color: #fb8e17;
  font-size: 20px;
}

.UserProfile .ProfilePicture {
	max-height: 150px;
	max-width: 150px;
	text-align: center;
}

.UserProfile .ProfilePicture img {
	object-fit: cover;
	width: 120px;
	height: 120px;
	overflow: hidden;
	margin: 0px auto;
	box-shadow: 0px 0px 6px rgba(20, 20, 20, 0.6);
	border-radius: 50%;
}

.UserProfile .ProfilePictureDefault .pic {
	border-radius: 50%;
	background-color: #8798ad;
	width: 120px;
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 48px;
	color: #f5f6fb;
	margin: 0px auto;
}

.UserProfile .ProfilePictureDefault .name {
	color: #222222;
}

.editor-container {
	background: #000000;
	height: 320px;
}

.MainContent .Content {
	min-height: 91vh;
}



.image-container {
	background-color: #000;
}

.image-container img {
	max-height: 400px;
}

.ContentSummaryList .list-styled {
	list-style: none;
}

.ContentSummaryList .list-styled li {
	margin-bottom: 15px;
}

.ContentSummaryList .list-styled i {
	margin-left: -30px;
	float: left;
	font-size: 22px;
	line-height: 24px;
}

.ContentSummaryList a:visited {
	color: #8798ad;
}


.ContentDashboard .FeaturedContent {
	height: 420px;
}

.ContentDashboard .FeaturedContent .img-wrapper {
	min-height: 200px;
	text-align: center;
}

.ContentDashboard .FeaturedContent .img-wrapper img {
	object-fit: cover;
	width: 100%;
	height: 320px;
	overflow: hidden;
}

.ContentDashboard .ListContent {
	height: 280px;
}

.ContentDashboard .ListContent .img-wrapper {
	min-height: 180px;
	margin-bottom: 12px;
	text-align: center;
}

.ContentDashboard .ListContent .img-wrapper img {
	object-fit: cover;
	width: 100%;
	height: 180px;
	overflow: hidden;
}

.ContentDashboard .ListContent a:visited {
	color: #8798ad;
}

.SignUp .fb-btn i {
  font-size: 24px;
  padding: 5px;
  margin-top: -3px;
}






@media (min-width: 992px) {
	.PromptDashboard .PromptDashboardSidebar {
		height: 100vh;
		position: -webkit-sticky; /* Safari */
		position: sticky;
		top: 0px;
		margin-top: -51px;
		padding-top: 51px;
		overflow: auto;
	}
}
.PromptDatePicker {
	width: 100%;
}

.PromptDatePicker .PromptDatePickerWrapper {
	display: block;
	margin-right: auto;
	margin-left: auto;
	width: 256px;
}

.PromptDatePicker .react-datepicker {
	font-family: inherit;
	font-size: 14px;
	background-color: none;
	color: #2e384d;
	border: none;
	margin: 0 auto;
	border-radius: 0px;
}

.PromptDatePicker .react-datepicker__header {
	background-color: #ffffff;
	border-bottom: 1px solid #e4e8f0;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}

.PromptDatePicker .react-datepicker__current-month,
.PromptDatePicker .react-datepicker-time__header,
.PromptDatePicker .react-datepicker-year-header {
	color: #2e384d;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 1.1px;
}

.PromptDatePicker .react-datepicker__day-name,
.PromptDatePicker .react-datepicker__day,
.PromptDatePicker .react-datepicker__time-name {
	color: #2e384d;
	width: 32px;
	line-height: 32px;
	text-align: center;
	margin: 0.166rem;
}

.PromptDatePicker .react-datepicker__navigation {
	line-height: 24px;
	top: 12px;
	height: 14px;
	width: 14px;
	border: 7px solid transparent;
}

.PromptDatePicker .react-datepicker__navigation--next {
	right: 8px;
	border-left-color: #8798ad;
}

.PromptDatePicker .react-datepicker__navigation--previous {
	left: 8px;
	border-right-color: #8798ad;
}

.PromptDatePicker .react-datepicker__day--today {
	color: #2e384d;
	background-color: #e4e8f0;
	border-radius: 0.3rem;
}

.PromptDatePicker .react-datepicker__day--selected,
.PromptDatePicker .react-datepicker__day--selected:hover,
.PromptDatePicker .react-datepicker__day--in-selecting-range,
.PromptDatePicker .react-datepicker__day--in-range,
.PromptDatePicker .react-datepicker__month-text--selected,
.PromptDatePicker .react-datepicker__month-text--in-selecting-range,
.PromptDatePicker .react-datepicker__month-text--in-range {
	border-radius: 0.3rem;
	background-color: #8c63ea;
	color: #f5f6fb;
}

.PromptDatePicker .react-datepicker::focus {
	background-color: #f5f6fb;
}

.PromptDatePicker .react-datepicker__day--disabled,
.PromptDatePicker .react-datepicker__month-text--disabled {
	cursor: default;
	color: #e4e8f0;
}


.PromptItem {
}

@media (min-width: 992px) {
	.PromptItem .card {
		height: 240px;
	}
}

@media (min-width: 768px) {
	.PromptItem .card {
		height: 240px;
	}
}


img {
  cursor: pointer;
  transition: 0.3s;
}

img:hover {
  opacity: 0.7;
}

#expandedImage {
  width: 100%;
}

#expandedImage:hover {
  opacity: 1;
  width: 100%;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .modal-width .modal-dialog {
    width: 750px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .modal-width .modal-dialog {
    width: 970px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .modal-width .modal-dialog {
    width: 1170px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .modal-width .modal-dialog {
    width: 1170px;
  }
}

.RewardImage img,
.RewardImage iframe,
.RewardImage embed,
.RewardImage video,
.RewardImage object {
	max-height: 320px;
	display: block;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

.RewardImage {
	overflow: hidden;
	height: 320px;
}

@media (min-width: 992px) {
	.RewardImage img,
	.RewardImage iframe,
	.RewardImage embed,
	.RewardImage video,
	.RewardImage object {
		max-width: 100%;
		margin: 0 auto;
		max-height: 360px;
	}
}

.ContentCraftDashboard .FeaturedContent {
  height: 420px;
}

.ContentCraftDashboard .FeaturedContent .img-wrapper {
  min-height: 200px;
  text-align: center;
}

.ContentCraftDashboard .FeaturedContent .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 320px;
  overflow: hidden;
}

.ContentCraftDashboard .ListContent {
  height: 280px;
}

.ContentCraftDashboard .ListContent .img-wrapper {
  min-height: 180px;
  margin-bottom: 12px;
  text-align: center;
}

.ContentCraftDashboard .ListContent .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.ContentCraftDashboard .ListContent a:visited {
  color: #8798ad;
}

.MainContent .Content {
	min-height: 91vh;
}

.CraftSummaryList .list-styled {
  list-style: none;
}

.CraftSummaryList .list-styled li {
  margin-bottom: 15px;
}

.CraftSummaryList .list-styled i {
  margin-left: -30px;
  float: left;
  font-size: 22px;
  line-height: 24px;
}

.CraftSummaryList a:visited {
  color: #8798ad;
}

.Dashboard .ProjectListItem {
  border-bottom: 1px solid #e4e8f0;
}

.WhatIsNewDashboard .FeaturedContent {
	height: 420px;
}

.WhatIsNewDashboard .FeaturedContent .img-wrapper {
	min-height: 200px;
	text-align: center;
}

.WhatIsNewDashboard .FeaturedContent .img-wrapper img {
	object-fit: cover;
	width: 100%;
	height: 320px;
	overflow: hidden;
}

.WhatIsNewDashboard .ListContent {
	height: 280px;
}

.WhatIsNewDashboard .ListContent .img-wrapper {
	min-height: 180px;
	margin-bottom: 12px;
	text-align: center;
}

.WhatIsNewDashboard .ListContent .img-wrapper img {
	object-fit: cover;
	width: 100%;
	height: 180px;
	overflow: hidden;
}

.WhatIsNewDashboard .ListContent a:visited {
	color: #8798ad;
}

.MainContent .Content {
	min-height: 91vh;
}

.ContentWhatIsNewSummaryList .list-styled {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
}

.ContentWhatIsNewSummaryList .list-styled li {
  margin-bottom: 15px;
}

.ContentWhatIsNewSummaryList .list-styled i {
  margin-left: -30px;
  float: left;
  font-size: 22px;
  line-height: 24px;
}

.ContentWhatIsNewSummaryList a:visited {
  color: #8798ad;
}

.InspirationDashboard .FeaturedContent {
  height: 420px;
}

.InspirationDashboard .FeaturedContent .img-wrapper {
  min-height: 200px;
  text-align: center;
}

.InspirationDashboard .FeaturedContent .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 320px;
  overflow: hidden;
}

.InspirationDashboard .ListContent {
  height: 280px;
}

.InspirationDashboard .ListContent .img-wrapper {
  min-height: 180px;
  margin-bottom: 12px;
  text-align: center;
}

.InspirationDashboard .ListContent .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.InspirationDashboard .ListContent a:visited {
  color: #8798ad;
}

.MainInspiration .Inspiration {
  min-height: 91vh;
}

.MainContent .Content {
	min-height: 91vh;
}

@charset "UTF-8";
/** The main css file includes custom css **/
/** custom
sass src/styles/index.scss src/index.css

poll for changes and automatically compiles
sass --watch src/styles/index.scss:src/index.css

Install bootstrap sass
npm install bootstrap-sass

**/
.mc {
  margin-left: auto;
  margin-right: auto;
}

.mt-0, h5, h4, .stats-block small, h3, h2, h1 {
  margin-top: 0;
}

.mr-0 {
  margin-right: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ml-0 {
  margin-left: 0;
}

.pt-0 {
  padding-top: 0;
}

.pr-0 {
  padding-right: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pl-0 {
  padding-left: 0;
}

@media (max-width: 767px) {
  .mc-sm {
    margin-right: auto;
    margin-left: auto;
  }

  .mt-xs {
    margin-top: 4px;
  }

  .mr-xs {
    margin-right: 4px;
  }

  .mb-xs {
    margin-bottom: 4px;
  }

  .ml-xs {
    margin-left: 4px;
  }

  .mt-sm {
    margin-top: 8px;
  }

  .mr-sm {
    margin-right: 8px;
  }

  .mb-sm {
    margin-bottom: 8px;
  }

  .ml-sm {
    margin-left: 8px;
  }

  .mt-md, .Content iframe,
.GuideContent iframe,
.RichTextEditor iframe,
.ManuscriptMain iframe, .Content img,
.Content embed,
.Content video,
.Content object,
.GuideContent img,
.GuideContent embed,
.GuideContent video,
.GuideContent object,
.RichTextEditor img,
.RichTextEditor embed,
.RichTextEditor video,
.RichTextEditor object,
.ManuscriptMain img,
.ManuscriptMain embed,
.ManuscriptMain video,
.ManuscriptMain object {
    margin-top: 11px;
  }

  .mr-md {
    margin-right: 11px;
  }

  .mb-md, .Content iframe,
.GuideContent iframe,
.RichTextEditor iframe,
.ManuscriptMain iframe, .Content img,
.Content embed,
.Content video,
.Content object,
.GuideContent img,
.GuideContent embed,
.GuideContent video,
.GuideContent object,
.RichTextEditor img,
.RichTextEditor embed,
.RichTextEditor video,
.RichTextEditor object,
.ManuscriptMain img,
.ManuscriptMain embed,
.ManuscriptMain video,
.ManuscriptMain object {
    margin-bottom: 11px;
  }

  .ml-md {
    margin-left: 11px;
  }

  .mt-lg {
    margin-top: 23px;
  }

  .mr-lg {
    margin-right: 23px;
  }

  .mb-lg {
    margin-bottom: 23px;
  }

  .ml-lg {
    margin-left: 23px;
  }

  .mt-xl {
    margin-top: 38px;
  }

  .mr-xl {
    margin-right: 38px;
  }

  .mb-xl {
    margin-bottom: 38px;
  }

  .ml-xl {
    margin-left: 38px;
  }

  .pt-xs, .card-list .footer,
.card-grid .footer, .card.card-xs {
    padding-top: 4px;
  }

  .pr-xs {
    padding-right: 4px;
  }

  .pb-xs, .card.card-xs {
    padding-bottom: 4px;
  }

  .pl-xs {
    padding-left: 4px;
  }

  .pt-sm, .card-list .body,
.card-grid .body, .card {
    padding-top: 8px;
  }

  .pr-sm, .card-list .footer,
.card-grid .footer, .card-list .body,
.card-grid .body, .card {
    padding-right: 8px;
  }

  .pb-sm, .card-list .footer,
.card-grid .footer, .card-list .body,
.card-grid .body, .card {
    padding-bottom: 8px;
  }

  .pl-sm, .card-list .footer,
.card-grid .footer, .card-list .body,
.card-grid .body, .card {
    padding-left: 8px;
  }

  .pt-md {
    padding-top: 11px;
  }

  .pr-md {
    padding-right: 11px;
  }

  .pb-md {
    padding-bottom: 11px;
  }

  .pl-md {
    padding-left: 11px;
  }

  .pt-lg {
    padding-top: 23px;
  }

  .pr-lg {
    padding-right: 23px;
  }

  .pb-lg {
    padding-bottom: 23px;
  }

  .pl-lg {
    padding-left: 23px;
  }

  .pt-xl {
    padding-top: 38px;
  }

  .pr-xl {
    padding-right: 38px;
  }

  .pb-xl {
    padding-bottom: 38px;
  }

  .pl-xl {
    padding-left: 38px;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .mc-md {
    margin-right: auto;
    margin-left: auto;
  }

  .mt-xs {
    margin-top: 5px;
  }

  .mr-xs {
    margin-right: 5px;
  }

  .mb-xs {
    margin-bottom: 5px;
  }

  .ml-xs {
    margin-left: 5px;
  }

  .mt-sm {
    margin-top: 10px;
  }

  .mr-sm {
    margin-right: 10px;
  }

  .mb-sm {
    margin-bottom: 10px;
  }

  .ml-sm {
    margin-left: 10px;
  }

  .mt-md, .Content iframe,
.GuideContent iframe,
.RichTextEditor iframe,
.ManuscriptMain iframe, .Content img,
.Content embed,
.Content video,
.Content object,
.GuideContent img,
.GuideContent embed,
.GuideContent video,
.GuideContent object,
.RichTextEditor img,
.RichTextEditor embed,
.RichTextEditor video,
.RichTextEditor object,
.ManuscriptMain img,
.ManuscriptMain embed,
.ManuscriptMain video,
.ManuscriptMain object {
    margin-top: 15px;
  }

  .mr-md {
    margin-right: 15px;
  }

  .mb-md, .Content iframe,
.GuideContent iframe,
.RichTextEditor iframe,
.ManuscriptMain iframe, .Content img,
.Content embed,
.Content video,
.Content object,
.GuideContent img,
.GuideContent embed,
.GuideContent video,
.GuideContent object,
.RichTextEditor img,
.RichTextEditor embed,
.RichTextEditor video,
.RichTextEditor object,
.ManuscriptMain img,
.ManuscriptMain embed,
.ManuscriptMain video,
.ManuscriptMain object {
    margin-bottom: 15px;
  }

  .ml-md {
    margin-left: 15px;
  }

  .mt-lg {
    margin-top: 30px;
  }

  .mr-lg {
    margin-right: 30px;
  }

  .mb-lg {
    margin-bottom: 30px;
  }

  .ml-lg {
    margin-left: 30px;
  }

  .mt-xl {
    margin-top: 50px;
  }

  .mr-xl {
    margin-right: 50px;
  }

  .mb-xl {
    margin-bottom: 50px;
  }

  .ml-xl {
    margin-left: 50px;
  }

  .pt-xs, .card-list .footer,
.card-grid .footer, .card.card-xs {
    padding-top: 5px;
  }

  .pr-xs {
    padding-right: 5px;
  }

  .pb-xs, .card.card-xs {
    padding-bottom: 5px;
  }

  .pl-xs {
    padding-left: 5px;
  }

  .pt-sm, .card-list .body,
.card-grid .body, .card {
    padding-top: 10px;
  }

  .pr-sm, .card-list .footer,
.card-grid .footer, .card-list .body,
.card-grid .body, .card {
    padding-right: 10px;
  }

  .pb-sm, .card-list .footer,
.card-grid .footer, .card-list .body,
.card-grid .body, .card {
    padding-bottom: 10px;
  }

  .pl-sm, .card-list .footer,
.card-grid .footer, .card-list .body,
.card-grid .body, .card {
    padding-left: 10px;
  }

  .pt-md {
    padding-top: 15px;
  }

  .pr-md {
    padding-right: 15px;
  }

  .pb-md {
    padding-bottom: 15px;
  }

  .pl-md {
    padding-left: 15px;
  }

  .pt-lg {
    padding-top: 30px;
  }

  .pr-lg {
    padding-right: 30px;
  }

  .pb-lg {
    padding-bottom: 30px;
  }

  .pl-lg {
    padding-left: 30px;
  }

  .pt-xl {
    padding-top: 50px;
  }

  .pr-xl {
    padding-right: 50px;
  }

  .pb-xl {
    padding-bottom: 50px;
  }

  .pl-xl {
    padding-left: 50px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .mc-lg {
    margin-right: auto;
    margin-left: auto;
  }

  .mt-xs {
    margin-top: 8px;
  }

  .mr-xs {
    margin-right: 8px;
  }

  .mb-xs {
    margin-bottom: 8px;
  }

  .ml-xs {
    margin-left: 8px;
  }

  .mt-sm {
    margin-top: 15px;
  }

  .mr-sm {
    margin-right: 15px;
  }

  .mb-sm {
    margin-bottom: 15px;
  }

  .ml-sm {
    margin-left: 15px;
  }

  .mt-md, .Content iframe,
.GuideContent iframe,
.RichTextEditor iframe,
.ManuscriptMain iframe, .Content img,
.Content embed,
.Content video,
.Content object,
.GuideContent img,
.GuideContent embed,
.GuideContent video,
.GuideContent object,
.RichTextEditor img,
.RichTextEditor embed,
.RichTextEditor video,
.RichTextEditor object,
.ManuscriptMain img,
.ManuscriptMain embed,
.ManuscriptMain video,
.ManuscriptMain object {
    margin-top: 23px;
  }

  .mr-md {
    margin-right: 23px;
  }

  .mb-md, .Content iframe,
.GuideContent iframe,
.RichTextEditor iframe,
.ManuscriptMain iframe, .Content img,
.Content embed,
.Content video,
.Content object,
.GuideContent img,
.GuideContent embed,
.GuideContent video,
.GuideContent object,
.RichTextEditor img,
.RichTextEditor embed,
.RichTextEditor video,
.RichTextEditor object,
.ManuscriptMain img,
.ManuscriptMain embed,
.ManuscriptMain video,
.ManuscriptMain object {
    margin-bottom: 23px;
  }

  .ml-md {
    margin-left: 23px;
  }

  .mt-lg {
    margin-top: 45px;
  }

  .mr-lg {
    margin-right: 45px;
  }

  .mb-lg {
    margin-bottom: 45px;
  }

  .ml-lg {
    margin-left: 45px;
  }

  .mt-xl {
    margin-top: 75px;
  }

  .mr-xl {
    margin-right: 75px;
  }

  .mb-xl {
    margin-bottom: 75px;
  }

  .ml-xl {
    margin-left: 75px;
  }

  .pt-xs, .card-list .footer,
.card-grid .footer, .card.card-xs {
    padding-top: 8px;
  }

  .pr-xs {
    padding-right: 8px;
  }

  .pb-xs, .card.card-xs {
    padding-bottom: 8px;
  }

  .pl-xs {
    padding-left: 8px;
  }

  .pt-sm, .card-list .body,
.card-grid .body, .card {
    padding-top: 15px;
  }

  .pr-sm, .card-list .footer,
.card-grid .footer, .card-list .body,
.card-grid .body, .card {
    padding-right: 15px;
  }

  .pb-sm, .card-list .footer,
.card-grid .footer, .card-list .body,
.card-grid .body, .card {
    padding-bottom: 15px;
  }

  .pl-sm, .card-list .footer,
.card-grid .footer, .card-list .body,
.card-grid .body, .card {
    padding-left: 15px;
  }

  .pt-md {
    padding-top: 23px;
  }

  .pr-md {
    padding-right: 23px;
  }

  .pb-md {
    padding-bottom: 23px;
  }

  .pl-md {
    padding-left: 23px;
  }

  .pt-lg {
    padding-top: 45px;
  }

  .pr-lg {
    padding-right: 45px;
  }

  .pb-lg {
    padding-bottom: 45px;
  }

  .pl-lg {
    padding-left: 45px;
  }

  .pt-xl {
    padding-top: 75px;
  }

  .pr-xl {
    padding-right: 75px;
  }

  .pb-xl {
    padding-bottom: 75px;
  }

  .pl-xl {
    padding-left: 75px;
  }
}
@media (min-width: 1200px) {
  .mc-xl {
    margin-right: auto;
    margin-left: auto;
  }

  .mt-xs {
    margin-top: 10px;
  }

  .mr-xs {
    margin-right: 10px;
  }

  .mb-xs {
    margin-bottom: 10px;
  }

  .ml-xs {
    margin-left: 10px;
  }

  .mt-sm {
    margin-top: 20px;
  }

  .mr-sm {
    margin-right: 20px;
  }

  .mb-sm {
    margin-bottom: 20px;
  }

  .ml-sm {
    margin-left: 20px;
  }

  .mt-md, .Content iframe,
.GuideContent iframe,
.RichTextEditor iframe,
.ManuscriptMain iframe, .Content img,
.Content embed,
.Content video,
.Content object,
.GuideContent img,
.GuideContent embed,
.GuideContent video,
.GuideContent object,
.RichTextEditor img,
.RichTextEditor embed,
.RichTextEditor video,
.RichTextEditor object,
.ManuscriptMain img,
.ManuscriptMain embed,
.ManuscriptMain video,
.ManuscriptMain object {
    margin-top: 30px;
  }

  .mr-md {
    margin-right: 30px;
  }

  .mb-md, .Content iframe,
.GuideContent iframe,
.RichTextEditor iframe,
.ManuscriptMain iframe, .Content img,
.Content embed,
.Content video,
.Content object,
.GuideContent img,
.GuideContent embed,
.GuideContent video,
.GuideContent object,
.RichTextEditor img,
.RichTextEditor embed,
.RichTextEditor video,
.RichTextEditor object,
.ManuscriptMain img,
.ManuscriptMain embed,
.ManuscriptMain video,
.ManuscriptMain object {
    margin-bottom: 30px;
  }

  .ml-md {
    margin-left: 30px;
  }

  .mt-lg {
    margin-top: 60px;
  }

  .mr-lg {
    margin-right: 60px;
  }

  .mb-lg {
    margin-bottom: 60px;
  }

  .ml-lg {
    margin-left: 60px;
  }

  .mt-xl {
    margin-top: 100px;
  }

  .mr-xl {
    margin-right: 100px;
  }

  .mb-xl {
    margin-bottom: 100px;
  }

  .ml-xl {
    margin-left: 100px;
  }

  .pt-xs, .card-list .footer,
.card-grid .footer, .card.card-xs {
    padding-top: 10px;
  }

  .pr-xs {
    padding-right: 10px;
  }

  .pb-xs, .card.card-xs {
    padding-bottom: 10px;
  }

  .pl-xs {
    padding-left: 10px;
  }

  .pt-sm, .card-list .body,
.card-grid .body, .card {
    padding-top: 20px;
  }

  .pr-sm, .card-list .footer,
.card-grid .footer, .card-list .body,
.card-grid .body, .card {
    padding-right: 20px;
  }

  .pb-sm, .card-list .footer,
.card-grid .footer, .card-list .body,
.card-grid .body, .card {
    padding-bottom: 20px;
  }

  .pl-sm, .card-list .footer,
.card-grid .footer, .card-list .body,
.card-grid .body, .card {
    padding-left: 20px;
  }

  .pt-md {
    padding-top: 30px;
  }

  .pr-md {
    padding-right: 30px;
  }

  .pb-md {
    padding-bottom: 30px;
  }

  .pl-md {
    padding-left: 30px;
  }

  .pt-lg {
    padding-top: 60px;
  }

  .pr-lg {
    padding-right: 60px;
  }

  .pb-lg {
    padding-bottom: 60px;
  }

  .pl-lg {
    padding-left: 60px;
  }

  .pt-xl {
    padding-top: 100px;
  }

  .pr-xl {
    padding-right: 100px;
  }

  .pb-xl {
    padding-bottom: 100px;
  }

  .pl-xl {
    padding-left: 100px;
  }
}
.MainHeader {
  margin-bottom: 51px;
}
.MainHeader .navbar {
  margin-bottom: 0px;
}

.navbar-default {
  border-bottom: 1px solid #e4e8f0;
  background-color: #ffffff;
  color: #2e384d;
  background-image: none;
}
.navbar-default a, .navbar-default .btn.btn-link, .navbar-default .text-active, .navbar-default .text-link {
  text-shadow: none;
  line-height: 21px;
}
.navbar-default .navbar-nav .dropdown-toggle {
  background-color: inherit;
  color: inherit;
}
.navbar-default .navbar-nav li > a, .navbar-default .navbar-nav li > .btn.btn-link, .navbar-default .navbar-nav li > .text-active, .navbar-default .navbar-nav li > .text-link {
  color: #2e384d;
  text-shadow: none;
  line-height: 21px;
}
.navbar-default .navbar-nav .active a, .navbar-default .navbar-nav .active .btn.btn-link, .navbar-default .navbar-nav .active .text-active, .navbar-default .navbar-nav .active .text-link,
.navbar-default .navbar-nav .active > a:hover,
.navbar-default .navbar-nav .active > a:focus,
.navbar-default .navbar-nav .open > a,
.navbar-default .navbar-nav .open > .btn.btn-link,
.navbar-default .navbar-nav .open > .text-active,
.navbar-default .navbar-nav .open > .text-link,
.navbar-default .navbar-nav .open > a:hover,
.navbar-default .navbar-nav .open > a:focus {
  color: #5a54fa;
  background-color: #ffffff;
  background-image: none;
  background-repeat: no-repeat;
  box-shadow: none;
}
.navbar-default .navbar-nav .active a::after, .navbar-default .navbar-nav .active .btn.btn-link::after, .navbar-default .navbar-nav .active .text-active::after, .navbar-default .navbar-nav .active .text-link::after,
.navbar-default .navbar-nav .active > a:hover::after,
.navbar-default .navbar-nav .active > a:focus::after,
.navbar-default .navbar-nav .open > a::after,
.navbar-default .navbar-nav .open > .btn.btn-link::after,
.navbar-default .navbar-nav .open > .text-active::after,
.navbar-default .navbar-nav .open > .text-link::after,
.navbar-default .navbar-nav .open > a:hover::after,
.navbar-default .navbar-nav .open > a:focus::after {
  content: "";
  display: block;
  position: absolute;
  bottom: 0;
  left: 10px;
  right: 10px;
  border-bottom: 3px solid #5a54fa;
}
.navbar-default .navbar-nav li > .dropdown-menu {
  border: 1px solid #e4e8f0;
  margin-top: 2px;
  margin-right: 8px;
  margin-left: 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-radius: 4px;
  box-shadow: none;
}
.navbar-default .navbar-nav li > .dropdown-menu li > a:hover, .navbar-default .navbar-nav li > .dropdown-menu li > .btn.btn-link:hover, .navbar-default .navbar-nav li > .dropdown-menu li > .text-active:hover, .navbar-default .navbar-nav li > .dropdown-menu li > .text-link:hover,
.navbar-default .navbar-nav li > .dropdown-menu .dropdown-menu > li > a:focus,
.navbar-default .navbar-nav li > .dropdown-menu .dropdown-menu > li > .btn.btn-link:focus,
.navbar-default .navbar-nav li > .dropdown-menu .dropdown-menu > li > .text-active:focus,
.navbar-default .navbar-nav li > .dropdown-menu .dropdown-menu > li > .text-link:focus {
  background-image: none;
  background-color: #e4e8f0;
}
.navbar-default .navbar-nav li > .dropdown-menu .dropdown-header {
  font-size: 14px;
  color: #8798ad;
}
.navbar-default .navbar-collapse.in,
.navbar-default .navbar-collapse.collapsing {
  background-color: #f5f6fb;
}
.navbar-default .material-icons,
.navbar-default .caret {
  color: #5861a6;
  font-size: 24px;
  margin-top: -2px;
}
.navbar-default .navbar-brand {
  height: 48px;
}
.navbar-default .navbar-brand img {
  width: 24px;
  height: auto;
  margin-right: 8px;
  margin-top: -2px;
}
.navbar-default .navbar-brand img.logo {
  width: 108px;
  margin-top: -8px;
}
.navbar-default .navbar-brand img.logo-icon {
  margin-right: 4px;
}
.navbar-default .nav-title {
  font-size: 18px;
  min-width: 144px;
}
.navbar-default .reward.material-icons {
  color: #fb8e17;
}
.navbar-default .reward.goal.material-icons {
  color: #ff3366;
}
.navbar-default li {
  margin-top: -3px;
}
.navbar-default .label {
  vertical-align: middle;
}
.navbar-default .btn-nav {
  margin-top: 10px;
  padding: 2px 10px;
  border-width: 1px;
}

.MainFooter {
  color: #e4e8f0;
  background-color: #2e384d;
  border-top: 1px solid #ccc;
  flex: 0 0 auto;
  padding: 15px 0;
  font-size: 14px;
}
.MainFooter a, .MainFooter .btn.btn-link, .MainFooter .text-active, .MainFooter .text-link {
  color: #f5f6fb;
}
.MainFooter ul {
  margin: 0;
}
.MainFooter ul li + li::before {
  content: " | ";
}

body {
  font-family: "Open Sans", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  color: #2e384d;
}

h1 {
  font-size: 25px;
  font-weight: 600;
  line-height: 36px;
}

h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}

h3 {
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 1.31px;
}

h4, .stats-block small {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 1.1px;
}

h5 {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
}

h6 {
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
}

p {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

p.body-1,
.body-1 {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
}

p.body-2,
.body-2 {
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  color: #8798ad;
}

p.sub-text,
.sub-text {
  font-size: 13px;
  font-weight: 400;
  line-height: 17px;
  color: #8798ad;
}

a, .btn.btn-link, .text-active, .text-link {
  color: #5a54fa;
}

a.active, .active.btn.btn-link, .active.text-active, .active.text-link {
  color: #23527c;
  text-decoration: underline;
}

a.hyperlink-1, .PillarLeft .nav-tabs a, .hyperlink-1.btn.btn-link, .PillarLeft .nav-tabs .btn.btn-link, .hyperlink-1.text-active, .PillarLeft .nav-tabs .text-active, .hyperlink-1.text-link, .PillarLeft .nav-tabs .text-link {
  font-size: 14px;
  line-height: 19px;
}

a.hyperlink-2, .hyperlink-2.btn.btn-link, .hyperlink-2.text-active, .hyperlink-2.text-link {
  font-size: 12px;
  line-height: 17px;
}

a.hyperlink-3, .hyperlink-3.btn.btn-link, .hyperlink-3.text-active, .hyperlink-3.text-link {
  color: inherit;
}

.text-link {
  cursor: pointer;
}

.text-link:hover,
.text-link:focus {
  color: #23527c;
  text-decoration: underline;
}

.text-active {
  cursor: pointer;
}

.text-muted {
  color: #8798ad;
}

hr {
  color: #e4e8f0;
  border-top: 1px solid #e4e8f0;
}

.list-styled i {
  color: #8de2d7;
}

.btn {
  background-image: none;
  background-repeat: no-repeat;
  text-shadow: none;
  box-shadow: none;
  text-decoration: none;
  border: 2px solid #8798ad;
}
.btn i {
  color: inherit;
}

.btn-xs {
  min-width: 64px;
}

.btn.btn-outline {
  background-color: #5a54fa;
  color: #ffffff;
}

.btn.btn-default {
  background-color: #ffffff;
  border-color: #5a54fa;
  color: #5a54fa;
}

.btn.btn-primary, .open .btn.dropdown-toggle.btn-primary,
.btn.btn-success {
  background-color: #5a54fa;
  border-color: #5a54fa;
  color: #ffffff;
}

.btn.btn-secondary {
  background-color: #d5d4ff;
  border-color: #d5d4ff;
  color: #5a54fa;
}

.btn.btn-link {
  border: 0px;
  color: #5a54fa;
}

.btn.btn-danger {
  background-color: #ffffff;
  border-color: #ff3366;
  color: #ff3366;
}

.btn.btn-default.active,
.btn.btn-default:hover,
.btn.btn-default:active {
  text-shadow: none;
  background-image: none;
  background-color: #eeeeff;
  text-decoration: none;
}

.btn.btn-primary.active,
.btn.btn-primary:hover,
.btn.btn-primary:active,
.btn.btn-success.active,
.btn.btn-success:hover,
.btn.btn-success:active {
  text-shadow: none;
  background-image: none;
  background-color: #423bf9;
  border-color: #423bf9;
  text-decoration: none;
}

.btn.btn-info {
  background-color: #00b9ff;
  border-color: #00b9ff;
}

.btn.btn-info.active,
.btn.btn-info:hover,
.btn.btn-info:active {
  background-color: #00a7e6;
  border-color: #00a7e6;
}

.btn.btn-warning {
  background-color: #fcab53;
  border-color: #fcab53;
}

.btn.btn-warning.active,
.btn.btn-warning:hover,
.btn.btn-warning:active {
  background-color: #fb9321;
  border-color: #fb9321;
}

.btn.btn-secondary.active,
.btn.btn-secondary:hover,
.btn.btn-secondary:focus,
.btn.btn-secondary:active {
  background-color: #c6c5ff;
  border-color: #c6c5ff;
  color: #423bf9;
}

.btn.btn-danger.active,
.btn.btn-danger:hover,
.btn.btn-danger:active {
  text-shadow: none;
  background-image: none;
  text-decoration: none;
  background-color: #ffe6ec;
  border-color: #ff245b;
  color: #ff1a53;
}

.btn.btn-secondary.active,
.btn.btn-secondary:focus,
.btn.btn-secondary:active {
  background-color: #d5d4ff;
  color: #5a54fa;
  border-color: #d5d4ff;
  box-shadow: none;
  text-decoration: none;
  text-shadow: none;
}

.btn .material-icons {
  font-size: inherit;
  line-height: 0;
}

.btn-toolbar > span {
  float: left;
  margin-left: 5px;
}

.btn[type=button] {
  outline: none;
}

.btn[type=button]::-moz-focus-inner {
  border: 0;
}

.dropdown-toggle.btn.btn-default {
  background-color: #ffffff;
  border-color: #5a54fa;
  color: #5a54fa;
  background-image: none;
  box-shadow: none;
  background-repeat: no-repeat;
}
.dropdown-toggle.btn.btn-default:hover, .dropdown-toggle.btn.btn-default:focus {
  background-color: #eeeeff;
  border-color: #5a54fa;
  color: #5a54fa;
  background-image: none;
  text-decoration: none;
  box-shadow: none;
}

.dropdown-toggle.btn.btn-success {
  background-color: #5a54fa;
  border-color: #5a54fa;
  color: #ffffff;
  background-image: none;
  box-shadow: none;
  background-repeat: no-repeat;
}
.dropdown-toggle.btn.btn-success:hover, .dropdown-toggle.btn.btn-success:focus {
  background-color: #5a54fa;
  border-color: #5a54fa;
  color: #ffffff;
  background-image: none;
  text-decoration: none;
  box-shadow: none;
}

.dropdown-toggle.btn.btn-secondary {
  background-color: #d5d4ff;
  border-color: #d5d4ff;
  color: #5a54fa;
  background-image: none;
  box-shadow: none;
  background-repeat: no-repeat;
}
.dropdown-toggle.btn.btn-secondary:hover, .dropdown-toggle.btn.btn-secondary:focus {
  background-color: #c6c5ff;
  border-color: #c6c5ff;
  color: #423bf9;
  background-image: none;
  text-decoration: none;
  box-shadow: none;
}

.dropdown-menu > li > a:hover, .dropdown-menu > li > .text-link:hover, .dropdown-menu > li > .text-active:hover, .dropdown-menu > li > .btn.btn-link:hover {
  background-image: none;
  background-repeat: no-repeat;
  background-color: transparent;
}

.nav-tabs {
  border-bottom: 1px solid #e4e8f0;
}

.nav-tabs > li > a:hover, .nav-tabs > li > .text-link:hover, .nav-tabs > li > .text-active:hover, .nav-tabs > li > .btn.btn-link:hover {
  background-color: #e4e8f0;
}

.numbered-list-group {
  margin: 0;
  border-radius: 4px;
  box-shadow: none;
}
.numbered-list-group .list-group-item {
  background-color: transparent;
  border: 0;
  line-height: 24px;
  font-size: 14px;
  color: #2e384d;
  text-decoration: none;
}
.numbered-list-group .list-group-item:first-child {
  border-top: 0;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
.numbered-list-group .list-group-item:last-child {
  margin-bottom: 0;
  border-bottom: 0;
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}
.numbered-list-group.styled i {
  font-size: 18px;
  width: 30px;
  height: 30px;
  line-height: 20px;
  display: inline-block;
  border-radius: 50%;
  padding: 4px 5px;
  color: #436b8a;
  border: 1px solid #5a54fa;
}
.numbered-list-group .list-group-item:hover {
  text-decoration: none;
  text-shadow: none;
  background-image: none;
  background-color: #d5d4ff;
  color: #5a54fa;
  border-radius: 3px;
}
.numbered-list-group .list-group-item.active,
.numbered-list-group .list-group-item.active:focus,
.numbered-list-group .list-group-item.active:hover,
.numbered-list-group .list-group-item.active:focus {
  text-decoration: none;
  text-shadow: none;
  background-image: none;
  background-color: #e4e8f0;
  color: #5a54fa;
  border-radius: 3px;
}

.preformatted-text {
  white-space: pre-line;
}

.material-icons {
  vertical-align: middle;
  line-height: inherit;
  color: #2e384d;
}

.material-icons.md-12 {
  font-size: 12px;
}

.material-icons.md-16 {
  font-size: 16px;
}

.material-icons.md-18 {
  font-size: 18px;
}

.material-icons.md-24 {
  font-size: 24px;
}

.material-icons.md-32 {
  font-size: 32px;
}

.material-icons.md-36 {
  font-size: 36px;
}

.material-icons.md-48 {
  font-size: 48px;
}

.material-icons.md-64 {
  font-size: 64px;
}

.material-icons.md-72 {
  font-size: 72px;
}

.material-icons.md-84 {
  font-size: 84px;
}

.material-icons.md-96 {
  font-size: 96px;
}

.material-icons.md-120 {
  font-size: 120px;
}

/* Rules for using icons as black on a light background. */
.material-icons.md-dark {
  color: rgba(0, 0, 0, 0.54);
}

.material-icons.md-dark.md-inactive {
  color: rgba(0, 0, 0, 0.26);
}

/* Rules for using icons as white on a dark background. */
.material-icons.md-light {
  color: white;
}

.material-icons.md-light.md-inactive {
  color: rgba(255, 255, 255, 0.3);
}

.material-icons.md-muted {
  color: #8798ad;
}

.RichEditor-editor {
  font-family: "Times New Roman", Times, serif;
  /*font-family: "Courier New", Courier, "Lucida Sans Typewriter",
  "Lucida Typewriter", monospace;*/
  font-size: 18px;
  cursor: text;
}

.Content,
.GuideContent,
.RichTextEditor,
.ManuscriptMain {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.5;
}
.Content img,
.Content embed,
.Content video,
.Content object,
.GuideContent img,
.GuideContent embed,
.GuideContent video,
.GuideContent object,
.RichTextEditor img,
.RichTextEditor embed,
.RichTextEditor video,
.RichTextEditor object,
.ManuscriptMain img,
.ManuscriptMain embed,
.ManuscriptMain video,
.ManuscriptMain object {
  max-width: 100%;
  height: auto !important;
}
.Content iframe,
.GuideContent iframe,
.RichTextEditor iframe,
.ManuscriptMain iframe {
  max-width: 100%;
}
.Content h1,
.GuideContent h1,
.RichTextEditor h1,
.ManuscriptMain h1 {
  font-size: 24px;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 1.3;
}
.Content h2,
.GuideContent h2,
.RichTextEditor h2,
.ManuscriptMain h2 {
  font-size: 22px;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 1.3;
}
.Content h3,
.GuideContent h3,
.RichTextEditor h3,
.ManuscriptMain h3 {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 1.3;
}
.Content h4, .Content .stats-block small, .stats-block .Content small,
.GuideContent h4,
.GuideContent .stats-block small,
.stats-block .GuideContent small,
.RichTextEditor h4,
.RichTextEditor .stats-block small,
.stats-block .RichTextEditor small,
.ManuscriptMain h4,
.ManuscriptMain .stats-block small,
.stats-block .ManuscriptMain small {
  font-size: 16px;
  font-weight: bold;
  letter-spacing: normal;
  line-height: 1.3;
}
.Content h5,
.GuideContent h5,
.RichTextEditor h5,
.ManuscriptMain h5 {
  font-size: 15px;
  letter-spacing: normal;
  line-height: 1.3;
}
.Content h6,
.GuideContent h6,
.RichTextEditor h6,
.ManuscriptMain h6 {
  font-size: 14px;
  letter-spacing: normal;
  line-height: 1.3;
}
.Content p,
.GuideContent p,
.RichTextEditor p,
.ManuscriptMain p {
  font-size: 16px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.5;
}
.Content a, .Content .text-link, .Content .text-active, .Content .btn.btn-link,
.GuideContent a,
.GuideContent .text-link,
.GuideContent .text-active,
.GuideContent .btn.btn-link,
.RichTextEditor a,
.RichTextEditor .text-link,
.RichTextEditor .text-active,
.RichTextEditor .btn.btn-link,
.ManuscriptMain a,
.ManuscriptMain .text-link,
.ManuscriptMain .text-active,
.ManuscriptMain .btn.btn-link {
  font-size: inherit;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.5;
}

/* 
* Font Custom: bare CSS
*/
@font-face {
  font-family: "social-icons";
  src: url(/static/media/social-icon-font.38320ace.eot);
  src: url(/static/media/social-icon-font.38320ace.eot?#iefix) format("embedded-opentype"), url(/static/media/social-icon-font.99c12eb4.woff) format("woff"), url(/static/media/social-icon-font.1b353087.ttf) format("truetype"), url(/static/media/social-icon-font.e00fb6d7.svg#social-icons) format("svg");
  font-weight: normal;
  font-style: normal;
}
.icon-aim:before,
.icon-amazon:before,
.icon-appstore:before,
.icon-basecamp:before,
.icon-behance:before,
.icon-blogger:before,
.icon-cargo:before,
.icon-cloudapp:before,
.icon-creativecommons:before,
.icon-delicious:before,
.icon-designmoo:before,
.icon-digg:before,
.icon-dribbble:before,
.icon-dropbox:before,
.icon-email:before,
.icon-envato:before,
.icon-etsy:before,
.icon-evernote:before,
.icon-facebook:before,
.icon-flickr:before,
.icon-forrst:before,
.icon-foursquare:before,
.icon-github:before,
.icon-goodreads:before,
.icon-googleplus:before,
.icon-instagram:before,
.icon-instapaper:before,
.icon-linkedin:before,
.icon-path:before,
.icon-paypal:before,
.icon-pinboard:before,
.icon-pinterest:before,
.icon-quora:before,
.icon-readernaut:before,
.icon-reddit:before,
.icon-rss:before,
.icon-skype:before,
.icon-soundcloud:before,
.icon-spotify:before,
.icon-squarespace:before,
.icon-tumblr:before,
.icon-twitter:before,
.icon-vimeo:before,
.icon-vsco:before,
.icon-wikipedia:before,
.icon-wordpress:before,
.icon-xbox:before,
.icon-yahoomessenger:before,
.icon-youtube:before,
.icon-zerply:before,
.icon-zootool:before {
  font-family: "social-icons";
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  text-decoration: inherit;
}

.icon-download:before {
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  text-decoration: inherit;
}

.icon-aim:before {
  content: "";
}

.icon-amazon:before {
  content: "";
}

.icon-appstore:before {
  content: "";
}

.icon-basecamp:before {
  content: "";
}

.icon-behance:before {
  content: "";
}

.icon-blogger:before {
  content: "";
}

.icon-cargo:before {
  content: "";
}

.icon-cloudapp:before {
  content: "";
}

.icon-creativecommons:before {
  content: "";
}

.icon-delicious:before {
  content: "";
}

.icon-designmoo:before {
  content: "";
}

.icon-digg:before {
  content: "";
}

.icon-dribbble:before {
  content: "";
}

.icon-dropbox:before {
  content: "";
}

.icon-email:before {
  content: "";
}

.icon-envato:before {
  content: "";
}

.icon-etsy:before {
  content: "";
}

.icon-evernote:before {
  content: "";
}

.icon-facebook:before {
  content: "";
}

.icon-flickr:before {
  content: "";
}

.icon-forrst:before {
  content: "";
}

.icon-foursquare:before {
  content: "";
}

.icon-github:before {
  content: "";
}

.icon-goodreads:before {
  content: "";
}

.icon-googleplus:before {
  content: "";
}

.icon-instagram:before {
  content: "";
}

.icon-instapaper:before {
  content: "";
}

.icon-linkedin:before {
  content: "";
}

.icon-path:before {
  content: "";
}

.icon-paypal:before {
  content: "";
}

.icon-pinboard:before {
  content: "";
}

.icon-pinterest:before {
  content: "";
}

.icon-quora:before {
  content: "";
}

.icon-readernaut:before {
  content: "";
}

.icon-reddit:before {
  content: "";
}

.icon-rss:before {
  content: "";
}

.icon-skype:before {
  content: "";
}

.icon-soundcloud:before {
  content: "";
}

.icon-spotify:before {
  content: "";
}

.icon-squarespace:before {
  content: "";
}

.icon-tumblr:before {
  content: "";
}

.icon-twitter:before {
  content: "";
}

.icon-vimeo:before {
  content: "";
}

.icon-vsco:before {
  content: "";
}

.icon-wikipedia:before {
  content: "";
}

.icon-wordpress:before {
  content: "";
}

.icon-xbox:before {
  content: "";
}

.icon-yahoomessenger:before {
  content: "";
}

.icon-youtube:before {
  content: "";
}

.icon-zerply:before {
  content: "";
}

.icon-zootool:before {
  content: "";
}

.icon-download {
  font-style: normal;
  text-decoration: none;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  line-height: 30px;
  display: inline-block;
  background-color: #5a54fa;
  cursor: pointer;
}
.icon-download:hover {
  color: #5a54fa;
}

.icon-download .material-icons {
  color: #fff;
  line-height: 30px;
  font-size: 20px;
  margin-top: -3px;
}

@media (min-width: 992px) {
  .Main {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  .Main .MainFooter {
    flex: 0 0 auto;
  }
  .Main .MainContent {
    flex: 1 0 auto;
  }
  .Main .MainHeader {
    flex: 0 0 auto;
  }
}
/* Medium devices (tablets, 992px and up) */
@media (min-width: 992px) {
  .PillarView .PillarCanvas .PillarCenter {
    margin-top: -51px;
    padding-top: 51px;
  }
  .PillarView .PillarCanvas .PillarCenter .FooterStickyRight {
    position: fixed;
    bottom: 16px;
    right: 18px;
    z-index: 100;
  }
  .PillarView .PillarCanvas .PillarLeft {
    height: 100vh;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0px;
    margin-top: -51px;
    padding-top: 51px;
    overflow: auto;
  }
  .PillarView .PillarCanvas .PillarLeft .FooterStickyLeft {
    position: fixed;
    bottom: 18px;
    left: 18px;
    z-index: 100;
  }
  .PillarView .PillarCanvas .PillarRight {
    height: 100vh;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0px;
    margin-top: -51px;
    padding-top: 51px;
    overflow: auto;
  }
}
.PillarCenter .PillarEditor {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  margin-bottom: 1.5rem;
  padding: 15px 8px;
}
.PillarCenter .PillarEditor .RichEditor-editor .public-DraftEditor-content {
  min-height: 72vh;
}
.PillarCenter .FooterStickyRight {
  position: -webkit-sticky;
  position: sticky;
  bottom: 10px;
  right: 18px;
  z-index: 100;
}

.PillarDescription {
  font-size: 14px;
  white-space: pre-wrap;
}

.modal-backdrop.opaque.in {
  filter: alpha(opacity=0);
  opacity: 0.8;
}

.PillarLeft .nav-tabs li.active {
  background-color: #f5f6fb;
  border-color: #e4e8f0;
}
.PillarLeft .nav-tabs li.active a, .PillarLeft .nav-tabs li.active .text-link, .PillarLeft .nav-tabs li.active .text-active, .PillarLeft .nav-tabs li.active .btn.btn-link,
.PillarLeft .nav-tabs li.active a:hover,
.PillarLeft .nav-tabs li.active a:focus,
.PillarLeft .nav-tabs li.active a:active {
  cursor: default;
  background-color: #f5f6fb;
  border-color: #e4e8f0;
  border-bottom-color: #f5f6fb;
  border-radius: 0px;
}
.PillarLeft .nav-tabs .FooterStickyLeft {
  position: -webkit-sticky;
  position: sticky;
  bottom: 10px;
  right: 18px;
  z-index: 100;
}
.PillarLeft .tab-content {
  border-left: 1px solid #e4e8f0;
  border-right: 1px solid #e4e8f0;
  border-bottom: 1px solid #e4e8f0;
  padding: 15px 15px 25px 15px;
  max-height: 56vh;
  overflow: scroll;
}
.PillarLeft .tab-content p {
  line-height: 1.5;
}
.PillarLeft .list-inline li {
  margin-top: 8px;
  margin-bottom: 8px;
}

@media (min-width: 992px) {
  .PillarOutline .PillarOutlineSidebar {
    height: 100vh;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0px;
    margin-top: -51px;
    padding-top: 51px;
    overflow: auto;
  }
}
@media (min-width: 992px) {
  .ManuscriptView .ManuscriptAside {
    height: 100vh;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
    top: 0px;
    margin-top: -51px;
    padding-top: 51px;
    overflow: auto;
  }
}
.table-hover > tbody > tr:hover {
  background-color: #f4f6f9;
}

.table td {
  word-break: break-word;
  min-width: 40px;
}

.modal-content {
  border-radius: 0;
  border: 1px solid #8798ad;
  background-clip: unset;
  box-shadow: none;
}
.modal-content .modal-header {
  padding: 25px 15px;
  background-color: #e4e8f0;
  border-bottom: 1px solid #e4e8f0;
}
.modal-content .modal-header .close {
  color: #5a54fa;
  opacity: 0.5;
}
.modal-content .modal-header .modal-title {
  font-size: 16px;
}
.modal-content .modal-footer {
  border-top: 1px solid #e4e8f0;
}

.form-control {
  color: #8798ad;
  border: 1px solid #e4e8f0;
}

.form-control:focus {
  color: #2e384d;
  border: 1px solid #8798ad;
}

.control-label {
  color: #2e384d;
  font-weight: 400;
  font-size: 14px;
}

.alert {
  background-image: none;
}

.alert-warning {
  color: #fb8e17;
  border-color: #fb8e17;
  background-color: #fffcf8;
}

.alert-success {
  color: #218175;
  border-color: #2fb6a5;
  background-color: #d6f4f1;
}

.alert-danger {
  color: #f5003d;
  border-color: #f5003d;
  background-color: #ffdbe4;
}

.alert-info {
  color: #008dc2;
  border-color: #008dc2;
  background-color: #a8e7ff;
}

.dropdown-block .dropdown-menu {
  width: 100%;
}

.label.tag {
  display: inline-block;
  line-height: 26px;
  padding: 0 8px 0 25px;
  position: relative;
  text-decoration: none;
  -webkit-transition: color 0.2s;
  background: #eee;
  color: #ffffff;
  border-radius: 0.25em;
}
.label.tag::after {
  background: #f5f6fb;
  border-radius: 10px;
  box-shadow: inset 0 1px rgba(0, 0, 0, 0.25);
  content: "";
  height: 6px;
  left: 10px;
  position: absolute;
  width: 6px;
  top: 10px;
}
.label.tag::before {
  background: #f5f6fb;
  border-bottom: 13px solid transparent;
  border-right: 13px solid #eee;
  border-top: 13px solid transparent;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
}

.label.label-default {
  background-color: #dfd3f9;
  color: #642de3;
}
.label.label-default.tag::before {
  border-right: 13px solid #dfd3f9;
}
.label.label-default.tag.label-sm {
  line-height: 22px;
}
.label.label-default.tag.label-sm::after {
  height: 6px;
  left: 10px;
  width: 6px;
  top: 8px;
}
.label.label-default.tag.label-sm::before {
  border-bottom: 11px solid transparent;
  border-top: 11px solid transparent;
  border-right: 11px solid #dfd3f9;
}

.label.label-emphasis {
  background-color: #faecf9;
  color: #c937bd;
}
.label.label-emphasis.tag::before {
  border-right: 13px solid #faecf9;
}

.label.label-success {
  background-color: #d6f4f1;
  color: #218175;
}
.label.label-success.tag::before {
  border-right: 13px solid #d6f4f1;
}

.label.label-info {
  background-color: #a8e7ff;
  color: #008dc2;
}
.label.label-info.tag::before {
  border-right: 10px solid #a8e7ff;
}

.label.label-warning {
  background-color: #fffcf8;
  color: #fb8e17;
}
.label.label-warning.tag::before {
  border-right: 13px solid #fffcf8;
}

.label.label-danger {
  background-color: #ffdbe4;
  color: #f5003d;
}
.label.label-danger.tag::before {
  border-right: 13px solid #ffdbe4;
}

.label.label-neutral {
  background-color: none;
  color: #8798ad;
}
.label.label-neutral.tag::before {
  border-right: 13px solid #ebeef1;
}

.card .label.tag::after {
  background: #fff;
}
.card .label.tag::before {
  background: #fff;
}

.bg-emphasis {
  background: #d667cd;
  color: #fff;
}

.help-block {
  font-size: 14px;
}

.stats-block {
  letter-spacing: 1.1px;
}
.tooltip {
  z-index: 1030;
}

.PillarCanvas .panel-default > .panel-heading {
  background-image: linear-gradient(to bottom, #ffffff 0%, #ffffff 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fff5f5f5", endColorstr="#ffe8e8e8", GradientType=0);
  background-repeat: repeat-x;
  color: #333333;
  background-color: #ffffff;
  border-color: #ddd0;
}
.PillarCanvas .panel {
  margin-bottom: -1px;
  background-color: #fff0;
  border: 1px solid transparent;
  border-radius: 0px;
  box-shadow: none;
}
.PillarCanvas .panel-default {
  border-color: #ddd0;
}

.card {
  background-color: #ffffff;
  border: 1px solid #e4e8f0;
  border-radius: 0px;
  word-wrap: break-word;
}

.card-neutral {
  border-top: 2px solid #8c63ea;
}

.card-success {
  border-top: 2px solid #50d2c2;
}

.card-emphasis {
  border-top: 2px solid #d667cd;
}

.card-warning {
  border-top: 2px solid #fcab53;
}

.card-info {
  border-top: 2px solid #00b9ff;
}

.card-danger {
  border-top: 2px solid #ff3366;
}

.card-grid {
  height: 240px;
}

.card-table {
  padding: 8px 0;
  margin-bottom: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.card-list,
.card-grid {
  padding: 0;
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;
  position: relative;
}
.card-list .body,
.card-grid .body {
  flex: 1 1;
  overflow: hidden;
}
.card-list .body .label-default,
.card-grid .body .label-default {
  background-color: #636363;
}
.card-list .body p,
.card-grid .body p {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: normal;
  line-height: 1.5;
}
.card-list .body i,
.card-grid .body i {
  font-size: 14px;
  width: 25px;
  height: 25px;
  line-height: 15px;
  display: inline-block;
  border-radius: 50%;
  padding: 4px 4px;
  background-color: #fcfcfc;
  color: #969696;
  border: 1px solid #969696;
}
.card-list .footer,
.card-grid .footer {
  self-align: footer;
}
.card-list .footer p,
.card-grid .footer p {
  margin: 0;
}

.RichTextEditor .label {
  font-size: 14px;
}

.RichTextEditor.readOnly .public-DraftEditor-content {
  opacity: 0.6;
}

.RichEditor-editor .public-DraftEditor-content {
  min-height: 72px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
  display: none;
}

.RichEditor-editor .RichEditor-blockquote {
  border-left: none;
  font-size: inherit;
  margin: inherit;
  padding: inherit;
  margin: 0 17%;
}

.RichEditor-editor .RichEditor-code-block {
  padding: 0px;
  margin: 0;
  line-height: 1.42857143;
  border: 0px;
  border-radius: 0px;
  font-size: 16;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
  background-color: rgba(0, 0, 0, 0.05);
  font-family: "Inconsolata", "Menlo", "Consolas", monospace;
  font-size: 16px;
  padding: 20px;
}

.RichEditorControls-root {
  position: -webkit-sticky;
  position: sticky;
  top: 64px;
  z-index: 2;
  margin: 15px 0px;
  padding: 4px;
  box-shadow: 0 1px 2px 0 #f5f6fb;
  background-color: #ffffff;
  border: 1px solid #e4e8f0;
  color: #e4e8f0;
}

.RichEditor-controls {
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.RichEditor-styleButton {
  font-family: "Helvetica", sans-serif;
  font-size: 16px;
  color: #8798ad;
  cursor: pointer;
  margin: 0 6px;
  padding: 2px 0;
  line-height: 1.2;
  vertical-align: middle;
}
.RichEditor-styleButton .material-icons {
  color: #8798ad;
  font-size: 20px;
}

.RichEditor-activeButton,
.RichEditor-activeButton i,
.RichEditor-activeButton .material-icons {
  color: #5a54fa;
}

.RichEditor-disabledButton,
.RichEditor-disabledButton i,
.RichEditor-disabledButton .material-icons {
  color: #b4bfcc;
}

.DraftEditor-editorContainer,
.DraftEditor-root,
.public-DraftEditor-content {
  height: inherit;
  text-align: left;
  text-align: initial;
}

.public-DraftEditor-content[contenteditable=true] {
  -webkit-user-modify: read-write-plaintext-only;
}

.DraftEditor-root {
  position: relative;
}

.DraftEditor-editorContainer {
  background-color: rgba(255, 255, 255, 0);
  border-left: 0.1px solid transparent;
  position: relative;
  z-index: 1;
}

.public-DraftEditor-block {
  position: relative;
}

.DraftEditor-alignLeft .public-DraftStyleDefault-block {
  text-align: left;
}

.DraftEditor-alignLeft .public-DraftEditorPlaceholder-root {
  left: 0;
  text-align: left;
}

.DraftEditor-alignCenter .public-DraftStyleDefault-block {
  text-align: center;
}

.DraftEditor-alignCenter .public-DraftEditorPlaceholder-root {
  margin: 0 auto;
  text-align: center;
  width: 100%;
}

.DraftEditor-alignRight .public-DraftStyleDefault-block {
  text-align: right;
}

.DraftEditor-alignRight .public-DraftEditorPlaceholder-root {
  right: 0;
  text-align: right;
}

.public-DraftEditorPlaceholder-root {
  color: #9197a3;
  position: absolute;
  z-index: 1;
}

.public-DraftEditorPlaceholder-hasFocus {
  color: #bdc1c9;
}

.DraftEditorPlaceholder-hidden {
  display: none;
}

.public-DraftStyleDefault-block {
  position: relative;
  white-space: pre-wrap;
}

.public-DraftStyleDefault-ltr {
  direction: ltr;
  text-align: left;
}

.public-DraftStyleDefault-rtl {
  direction: rtl;
  text-align: right;
}

.public-DraftStyleDefault-listLTR {
  direction: ltr;
}

.public-DraftStyleDefault-listRTL {
  direction: rtl;
}

.public-DraftStyleDefault-ol,
.public-DraftStyleDefault-ul {
  margin: 16px 0;
  padding: 0;
  /*list-style-position: inside;*/
}

.public-DraftStyleDefault-ol li *,
.public-DraftStyleDefault-ul li * {
  display: inline;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR {
  margin-left: 1.5em;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listRTL {
  margin-right: 1.5em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listLTR {
  margin-left: 3em;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-listRTL {
  margin-right: 3em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listLTR {
  margin-left: 4.5em;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-listRTL {
  margin-right: 4.5em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listLTR {
  margin-left: 6em;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-listRTL {
  margin-right: 6em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listLTR {
  margin-left: 7.5em;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-listRTL {
  margin-right: 7.5em;
}

.public-DraftStyleDefault-unorderedListItem {
  list-style-type: square;
  position: relative;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth0 {
  list-style-type: disc;
}

.public-DraftStyleDefault-unorderedListItem.public-DraftStyleDefault-depth1 {
  list-style-type: circle;
}

.public-DraftStyleDefault-orderedListItem {
  list-style-type: none;
  position: relative;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listLTR:before {
  left: -36px;
  position: absolute;
  text-align: right;
  width: 30px;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-listRTL:before {
  position: absolute;
  right: -36px;
  text-align: left;
  width: 30px;
}

.public-DraftStyleDefault-orderedListItem:before {
  content: counter(ol0) ". ";
  counter-increment: ol0;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth1:before {
  content: counter(ol1, lower-alpha) ". ";
  counter-increment: ol1;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth2:before {
  content: counter(ol2, lower-roman) ". ";
  counter-increment: ol2;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth3:before {
  content: counter(ol3) ". ";
  counter-increment: ol3;
}

.public-DraftStyleDefault-orderedListItem.public-DraftStyleDefault-depth4:before {
  content: counter(ol4, lower-alpha) ". ";
  counter-increment: ol4;
}

.public-DraftStyleDefault-depth0.public-DraftStyleDefault-reset {
  counter-reset: ol0;
}

.public-DraftStyleDefault-depth1.public-DraftStyleDefault-reset {
  counter-reset: ol1;
}

.public-DraftStyleDefault-depth2.public-DraftStyleDefault-reset {
  counter-reset: ol2;
}

.public-DraftStyleDefault-depth3.public-DraftStyleDefault-reset {
  counter-reset: ol3;
}

.public-DraftStyleDefault-depth4.public-DraftStyleDefault-reset {
  counter-reset: ol4;
}

/* Add paddings on div/default blocks */
.public-DraftEditor-content > [data-contents=true] > div {
  margin: 0;
}

.text-left .public-DraftStyleDefault-block {
  text-align: left;
}

.text-right .public-DraftStyleDefault-block {
  text-align: right;
}

.text-center .public-DraftStyleDefault-block {
  text-align: center;
}

.text-justify .public-DraftStyleDefault-block {
  text-align: justify;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f5f6fb;
}

.input-group-btn .btn.btn-default {
  background-color: transparent;
  border-color: #5a54fa;
  color: #5a54fa;
  border: 1px solid #e4e8f0;
}

#root input {
  font-size: 16px;
}

.find-loader {
  border: 2px solid #d5d4ff;
  border-radius: 50%;
  border-top: 2px solid #5a54fa;
  width: 20px;
  height: 20px;
  display: block;
  float: right;
  margin-left: 8px;
  -webkit-animation: spin 0.5s linear infinite;
  /* Safari */
  animation: spin 0.5s linear infinite;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.badge {
  color: #ffffff;
  background-color: #5a54fa;
}

.radio-inline,
.checkbox-inline {
  font-size: 14px;
}

/* Highlight DOM fragments to reveal the layout */

/*# sourceMappingURL=index.css.map */

