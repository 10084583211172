.ContentSummaryList .list-styled {
	list-style: none;
}

.ContentSummaryList .list-styled li {
	margin-bottom: 15px;
}

.ContentSummaryList .list-styled i {
	margin-left: -30px;
	float: left;
	font-size: 22px;
	line-height: 24px;
}

.ContentSummaryList a:visited {
	color: #8798ad;
}
