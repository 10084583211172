@media (min-width: 992px) {
	.PromptDashboard .PromptDashboardSidebar {
		height: 100vh;
		position: -webkit-sticky; /* Safari */
		position: sticky;
		top: 0px;
		margin-top: -51px;
		padding-top: 51px;
		overflow: auto;
	}
}
.PromptDatePicker {
	width: 100%;
}

.PromptDatePicker .PromptDatePickerWrapper {
	display: block;
	margin-right: auto;
	margin-left: auto;
	width: 256px;
}

.PromptDatePicker .react-datepicker {
	font-family: inherit;
	font-size: 14px;
	background-color: none;
	color: #2e384d;
	border: none;
	margin: 0 auto;
	border-radius: 0px;
}

.PromptDatePicker .react-datepicker__header {
	background-color: #ffffff;
	border-bottom: 1px solid #e4e8f0;
	border-top-left-radius: 0px;
	border-top-right-radius: 0px;
}

.PromptDatePicker .react-datepicker__current-month,
.PromptDatePicker .react-datepicker-time__header,
.PromptDatePicker .react-datepicker-year-header {
	color: #2e384d;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 1.1px;
}

.PromptDatePicker .react-datepicker__day-name,
.PromptDatePicker .react-datepicker__day,
.PromptDatePicker .react-datepicker__time-name {
	color: #2e384d;
	width: 32px;
	line-height: 32px;
	text-align: center;
	margin: 0.166rem;
}

.PromptDatePicker .react-datepicker__navigation {
	line-height: 24px;
	top: 12px;
	height: 14px;
	width: 14px;
	border: 7px solid transparent;
}

.PromptDatePicker .react-datepicker__navigation--next {
	right: 8px;
	border-left-color: #8798ad;
}

.PromptDatePicker .react-datepicker__navigation--previous {
	left: 8px;
	border-right-color: #8798ad;
}

.PromptDatePicker .react-datepicker__day--today {
	color: #2e384d;
	background-color: #e4e8f0;
	border-radius: 0.3rem;
}

.PromptDatePicker .react-datepicker__day--selected,
.PromptDatePicker .react-datepicker__day--selected:hover,
.PromptDatePicker .react-datepicker__day--in-selecting-range,
.PromptDatePicker .react-datepicker__day--in-range,
.PromptDatePicker .react-datepicker__month-text--selected,
.PromptDatePicker .react-datepicker__month-text--in-selecting-range,
.PromptDatePicker .react-datepicker__month-text--in-range {
	border-radius: 0.3rem;
	background-color: #8c63ea;
	color: #f5f6fb;
}

.PromptDatePicker .react-datepicker::focus {
	background-color: #f5f6fb;
}

.PromptDatePicker .react-datepicker__day--disabled,
.PromptDatePicker .react-datepicker__month-text--disabled {
	cursor: default;
	color: #e4e8f0;
}
