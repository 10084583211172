.DnDColContent {
	/*To prevent the user selecting text as they drag apply this style*/
	/*user-select: none;*/
	/*padding: 15px;*/
	padding-bottom: 15px;
	min-height: 240px;
}

.DnDContainer {
	overflow-y: auto;
}

.DnDColContent .card-grid.movable:hover,
.DnDColContent .card-grid.movable:active,
.DnDColContent .card-grid.movable:focus {
	/*background: #f5f5f5;*/
	border-bottom: 1px solid #dddddd;
	cursor: move;
	box-shadow: 0 4px 6px 0px rgba(0, 40, 100, 0.25);
	margin: -0.2rem -0.2rem 1.7rem 0.2rem;

	/* Apply no select */
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                 supported by Chrome and Opera */
}

.DnDRowContent .drag-handle {
	float: left;
	margin-left: -4px;
}

.DnDRowContent .drag-handle .material-icons {
	color: #d5d4ff;
}

.DnDRowContent .drag-handle:hover,
.DnDRowContent .drag-handle:active,
.DnDRowContent .drag-handle:focus {
	cursor: move;
}

.DnDRowContent .card-table.movable:active,
.DnDRowContent .card-table.movable:focus {
	border-bottom: 1px solid #e4e8f0;
	background: #f5f5f5;

	/* Apply no select */
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
                                 supported by Chrome and Opera */
}

.DnDMoving .card {
	background: #f5f5f5;
	border-bottom: 1px solid #e4e8f0;
}
