.UserProfile .ProfilePicture {
	max-height: 150px;
	max-width: 150px;
	text-align: center;
}

.UserProfile .ProfilePicture img {
	object-fit: cover;
	width: 120px;
	height: 120px;
	overflow: hidden;
	margin: 0px auto;
	box-shadow: 0px 0px 6px rgba(20, 20, 20, 0.6);
	border-radius: 50%;
}

.UserProfile .ProfilePictureDefault .pic {
	border-radius: 50%;
	background-color: #8798ad;
	width: 120px;
	height: 120px;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 48px;
	color: #f5f6fb;
	margin: 0px auto;
}

.UserProfile .ProfilePictureDefault .name {
	color: #222222;
}
