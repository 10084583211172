.InspirationDashboard .FeaturedContent {
  height: 420px;
}

.InspirationDashboard .FeaturedContent .img-wrapper {
  min-height: 200px;
  text-align: center;
}

.InspirationDashboard .FeaturedContent .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 320px;
  overflow: hidden;
}

.InspirationDashboard .ListContent {
  height: 280px;
}

.InspirationDashboard .ListContent .img-wrapper {
  min-height: 180px;
  margin-bottom: 12px;
  text-align: center;
}

.InspirationDashboard .ListContent .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.InspirationDashboard .ListContent a:visited {
  color: #8798ad;
}
