.RewardSharer {
}

.RewardSharer img,
.RewardSharer iframe,
.RewardSharer embed,
.RewardSharer video,
.RewardSharer object {
	max-width: 100%;
}
