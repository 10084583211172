.ProjectGoalUpdate .react-datepicker__input-container input {
	color: #8798ad;
	border: 1px solid #e4e8f0;
	display: block;
	width: 100%;
	height: 34px;
	padding: 6px 12px;
	font-size: 14px;
	line-height: 1.42857143;
	background-color: #fff;
	background-image: none;
	border-radius: 4px;
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-webkit-transition: border-color ease-in-out 0.15s,
		box-shadow ease-in-out 0.15s;
	-webkit-transition: border-color ease-in-out 0.15s,
		-webkit-box-shadow ease-in-out 0.15s;
	transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
}

.ProjectGoalUpdate .react-datepicker-wrapper {
	display: block;
}

.ProjectGoalUpdate .react-datepicker__day-name,
.ProjectGoalUpdate .react-datepicker__day,
.ProjectGoalUpdate .react-datepicker__time-name {
	color: #000;
	display: inline-block;
	width: 2.4rem;
	line-height: 2.4rem;
	text-align: center;
	margin: 0.25rem;
	font-size: 1.2rem;
}

.ProjectGoalUpdate .react-datepicker__current-month,
.ProjectGoalUpdate .react-datepicker-time__header,
.ProjectGoalUpdate .react-datepicker-year-header {
	margin-top: 0;
	color: #000;
	font-weight: bold;
	font-size: 1.5rem;
}

.ProjectGoalUpdate .react-datepicker__navigation--previous {
	left: 0px;
}
.ProjectGoalUpdate .react-datepicker__navigation--next {
	right: 0px;
}

.ProjectGoalUpdate .react-datepicker__navigation {
	top: 15px;
	height: 12px;
	width: 18px;
}
