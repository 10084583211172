.PromptItem {
}

@media (min-width: 992px) {
	.PromptItem .card {
		height: 240px;
	}
}

@media (min-width: 768px) {
	.PromptItem .card {
		height: 240px;
	}
}
