.PillarOutline .btn.btn-secondary.active,
.PillarOutline .btn.btn-secondary:focus,
.PillarOutline .btn.btn-secondary:active {
  background-color: #b5b2fe;
  border-color: #b5b2fe;
}

.PillarOutline .btn-group .btn.btn-secondary {
  outline: none;
}
