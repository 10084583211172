.RewardImage img,
.RewardImage iframe,
.RewardImage embed,
.RewardImage video,
.RewardImage object {
	max-height: 320px;
	display: block;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}

.RewardImage {
	overflow: hidden;
	height: 320px;
}

@media (min-width: 992px) {
	.RewardImage img,
	.RewardImage iframe,
	.RewardImage embed,
	.RewardImage video,
	.RewardImage object {
		max-width: 100%;
		margin: 0 auto;
		max-height: 360px;
	}
}
