.Tracker .btn-xs {
  min-width: 24px;
}

.Tracker .list-styled {
  display: flex;
  justify-content: center;
}

.Tracker .list-styled i {
  /* margin-left: -30px;
  margin-top: 0px;
  float: left; */
  font-size: 25px;
  color: #fcab53;
}

.Tracker .list-styled {
  list-style: none;
}
