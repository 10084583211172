img {
  cursor: pointer;
  transition: 0.3s;
}

img:hover {
  opacity: 0.7;
}

#expandedImage {
  width: 100%;
}

#expandedImage:hover {
  opacity: 1;
  width: 100%;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
  .modal-width .modal-dialog {
    width: 750px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
  .modal-width .modal-dialog {
    width: 970px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .modal-width .modal-dialog {
    width: 1170px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .modal-width .modal-dialog {
    width: 1170px;
  }
}
