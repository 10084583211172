.ContentCrafts .img-wrapper {
  min-height: 187px;
  text-align: center;
}

.ContentCrafts .img-wrapper img {
  object-fit: cover;
  width: 100%;
  height: 180px;
  overflow: hidden;
}

.ContentCrafts a:visited {
  color: #8798ad;
}
