.WhatIsNew .dropdown-menu {
  width: 380px;
}

.WhatIsNew .dropdown-menu > li > a {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333333;
  white-space: normal;
}

.WhatIsNew .dropdown-menu > li > a > p > .what-is-new-hyperlink {
  color: #5a53fa;
}

.WhatIsNew .dropdown-menu > li > a > p > .what-is-new-hyperlink:hover {
  text-decoration: underline;
}

.WhatIsNew .indicator {
  width: 8px;
  height: 8px;
  display: block;
  background-color: #ffb818;
  border-radius: 50%;
  position: absolute;
  top: 10px;
  left: 100px;
}

.WhatIsNew .description {
  font-size: 14px;
}
