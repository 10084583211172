.Login .list-inline li + li::before {
  content: " | ";
}
.list-inline {
  color: #9f9fa1;
  font-size: 14px;
}

.LoginForm .fb-btn i {
  font-size: 24px;
  padding: 5px;
  margin-top: -3px;
}
