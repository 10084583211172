.ProjectSummary .ProjectProgress .progress-container {
	height: 84px;
	width: 84px;
}

.ProjectSummary .ProjectReward i {
	font-size: 20px;
	color: #fcab53;
}

.ProjectSummary .ProjectReward i.goal {
	font-size: 20px;
	color: #ff3366;
}

.ProjectSummary .ProjectReward .btn {
	padding-left: 4px;
	padding-right: 4px;
}
