.Contributions .btn-xs {
	min-width: 24px;
}

.Contributions .list-styled {
	display: flex;
	justify-content: center;
}

.Contributions .list-styled i {
	margin-left: -30px;
	margin-top: 0px;
	float: left;
	font-size: 25px;
	color: #fcab53;
}

.Contributions .list-styled {
	list-style: none;
}
