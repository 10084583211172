.Loader .spinner {
	width: 64px;
	height: 64px;
	float: none;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.Loader .spinner:after {
	content: " ";
	display: block;
	width: 46px;
	height: 46px;
	margin: 1px;
	border-radius: 50%;
	border: 5px solid red;
	border-color: #8798ad transparent #8798ad transparent;
	animation: spinner 1.2s linear infinite;
	opacity: 0.4;
}

@keyframes spinner {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
