.MonitorTimer .timer {
	font-size: 24px;
	font-weight: 300;
	text-align: center;
	width: 144px;
}

.MonitorTimer .material-icons.md-28,
.MonitorTimer .material-icons.md-24 {
	cursor: pointer;
	font-size: 28px;
}
