.SocialSharing ul.soc {
	padding-left: 0;
	padding-bottom: 0;
	margin-bottom: 0;
}
.SocialSharing ul.soc li {
	display: inline;
	color: #5a54fa;
}

.SocialSharing ul.soc li span,
.SocialSharing ul.soc li a {
	font-size: 30px;
	text-decoration: none;
	cursor: pointer;
	color: #5a54fa;
}
