.CraftSummaryList .list-styled {
  list-style: none;
}

.CraftSummaryList .list-styled li {
  margin-bottom: 15px;
}

.CraftSummaryList .list-styled i {
  margin-left: -30px;
  float: left;
  font-size: 22px;
  line-height: 24px;
}

.CraftSummaryList a:visited {
  color: #8798ad;
}
