.ProjectDashboard .progress {
  width: 84px;
  margin-bottom: 0;
  height: 12px;
}
.ProjectDashboard .ProgressWrapper {
  width: 144px;
  padding: 12px 0;
}

.ProjectDashboard .ProjectProgress .progress-container {
  height: 196px;
  width: 196px;
}

.ProjectDashboard .ProjectProgress .list-styled {
  display: flex;
  justify-content: center;
}

.ProjectDashboard .ProjectProgress .chart-downloader {
  margin-top: -40px;
}

.ProjectDashboard .ProjectProgress .list-styled,
.ProjectDashboard .ProjectRewards .list-styled {
  list-style: none;
  padding-top: 8px;
  padding-bottom: 8px;
}

.ProjectDashboard .ProjectRewards .list-styled li {
  margin-bottom: 20px;
}

.ProjectDashboard .ProjectRewards .list-styled i,
.ProjectDashboard .ProjectProgress .list-styled i {
  margin-left: -30px;
  margin-top: 0px;
  float: left;
  font-size: 24px;
  color: #fcab53;
}

.ProjectDashboard .ProjectRewards .list-styled i.goal {
  color: #ff3366;
}

.ProjectDashboard .ProjectRewards .RewardContent p:hover {
  cursor: pointer;
  color: #5a54fa;
  text-decoration: underline;
}

.ProjectDashboard a .material-icons {
  color: inherit;
}

.ProjectDashboard h4,
.ProjectDashboard .stats-block small {
  font-size: 16px;
  font-weight: 500;
}

.ProjectDashboard .pillar-summary {
  display: block;
  width: 25px;
  height: 20px;
  background: #dfe1f6;
  margin-right: 10px;
  margin-bottom: 8px;
  float: left;
}

.ProjectDashboard .pillar-active {
  background: rgb(160, 124, 255);
}

.ProjectDashboard .summary-card {
  min-height: 355px;
}

.ProjectDashboard .activity-container {
  background: #ddd;
}

.ProjectDashboard .col-1-override {
  width: 11%;
  padding-right: 0;
}
