.NoteCreate {
  /*box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
	background-color: #fff;
	background-clip: border-box;
	border: 1px solid rgba(0, 40, 100, 0.12);
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
	margin-bottom: 1.5rem;*/
}

.NoteCreate .RichEditor-editor .public-DraftEditor-content {
  min-height: 48px;
}

.PillarRight .drag-handle {
  cursor: grab;
}
