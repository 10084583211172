.RewardDector {
  /* position: absolute;
  top: 55px;
  left: 15px;
  z-index: 2; */
}

.RewardDector .label.label-warning {
  background-color: "#f2dede";
  color: #fb8e17;
  cursor: pointer;
  font-size: 14px;
}
.RewardDector .label.label-warning .material-icons {
  color: #fb8e17;
  font-size: 20px;
}
