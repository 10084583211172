.card.NoteRead {
	background-color: #f5f6fb;
}

.card.NoteEdit {
}

.Note .RichTextEditor {
	margin-right: 8px;
}

.Note .close {
	margin-right: -4px;
	margin-top: 0px;
	color: #8798ad;
}

.Note .RichEditor-editor .public-DraftEditor-content {
	min-height: 40px;
}

.Note ul {
	margin-bottom: 0;
}

.Note {
	position: relative;
	margin-bottom: 1.5rem;
}

.Note .modal,
.Note .modal-backdrop {
	position: absolute;
}
.Note .modal-dialog {
	width: 80%;
	margin: 10px auto;
}
