.WhatIsNewDashboard .FeaturedContent {
	height: 420px;
}

.WhatIsNewDashboard .FeaturedContent .img-wrapper {
	min-height: 200px;
	text-align: center;
}

.WhatIsNewDashboard .FeaturedContent .img-wrapper img {
	object-fit: cover;
	width: 100%;
	height: 320px;
	overflow: hidden;
}

.WhatIsNewDashboard .ListContent {
	height: 280px;
}

.WhatIsNewDashboard .ListContent .img-wrapper {
	min-height: 180px;
	margin-bottom: 12px;
	text-align: center;
}

.WhatIsNewDashboard .ListContent .img-wrapper img {
	object-fit: cover;
	width: 100%;
	height: 180px;
	overflow: hidden;
}

.WhatIsNewDashboard .ListContent a:visited {
	color: #8798ad;
}
