.ProjectCreateModal .form-group .help-block {
	color: #737373;
	font-size: 14px;
	font-weight: normal;
}

.ProjectImportSummary .table-responsive td {
	min-width: 120px;
}

.ProjectImportSummary .table-responsive td:first-child {
	min-width: 48px;
}

.ProjectImportSummary .table-responsive td:last-child {
	min-width: 64px;
}
