.ContributionHeatmap a .material-icons {
	color: inherit;
}

.ContributionHeatmap .chart-downloader {
	margin-top: -30px;
}

.ContributionHeatmap .heat-map-container {
	height: 240px;
	width: 296px;
	overflow-y: hidden;
}

@media (min-width: 576px) {
	.ContributionHeatmap .heat-map-container {
		height: 240px;
		width: 360px;
	}
}

@media (min-width: 768px) {
	.ContributionHeatmap .heat-map-container {
		height: 240px;
		width: 360px;
	}
}
@media (min-width: 992px) {
	.ContributionHeatmap .heat-map-container {
		height: 240px;
		width: 360px;
	}
}

@media (min-width: 1200px) {
	.ContributionHeatmap .heat-map-container {
		height: 240px;
		width: 360px;
	}
}
