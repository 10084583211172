.search-and-replace-highlight {
  border-radius: 3px;
  background: #fcab53a6;
  padding: 3px;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.search-and-replace-index {
  border-radius: 3px;
  background: #ee53fca6;
  padding: 3px;
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

.match-count {
  text-align: right;
  position: absolute;
  right: 16px;
}

.react-draggable {
  max-width: 370px;
}
.react-draggable .modal-dialog {
  max-width: 370px;
}

.react-draggable .modal-content {
  border-radius: 1;
  border: 1px solid #e6e6e6;
  background-clip: unset;
  box-shadow: 1px 1px 2px 1px rgb(0 0 0 / 15%);
}

.react-draggable .modal-content .modal-header {
  padding: 25px 15px;
  background-color: #e4e8f0;
  cursor: move;
  border-bottom: 1px solid #e4e8f0;
}

.react-draggable .modal-content .modal-header .modal-title {
  font-weight: 600;
  font-size: 16px;
  color: #3a3a3a;
}
